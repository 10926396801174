.footer-text {
  text-align: center;
  color: #868e96;
}

.footer-div {
  margin-top: 2rem;
}

/* Media Query */
@media (max-width: 768px) {
  .footer-text {
    font-size: 10px;
  }
}
@media (max-width: 320px) {
  .footer-text {
    font-size: 8px;
  }
}
